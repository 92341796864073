<app-header headerTitle="Datenschutz"></app-header>

<section class="datenschutz padding">
  <div class="content-wrapper">
    <div class="content-item">
      <h3>1. Verantwortliche Stelle</h3>
      <p>
        Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Sinne der DSGVO ist:
      </p>
      <p>
        Dr. med. Amma Yeboah <br />
        Theodor-Heus-Ring 23 <br />
        50668 Köln <br />
      </p>
      <p>
        Sofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch uns nach Maßgabe dieser Datenschutzbestimmungen insgesamt oder für einzelne Maßnahmen widersprechen wollen, können Sie Ihren Widerspruch an oben genannte verantwortliche Stelle richten. Sie können diese Datenschutzerklärung jederzeit speichern und ausdrucken.
      </p>
    </div>
    <div class="content-item">
      <h3>2. Allgemeine Nutzung der Webseite</h3>

      <h4>Zugriffsdaten</h4>
      <p>
        Im Internet werden technisch automatisch Informationen über Sie gesammelt, wenn Sie diese Webseite nutzen. Zu den Zugriffsdaten gehören Name und URL der abgerufenen Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf (HTTP response code), Browsertyp und Browserversion, Betriebssystem, Referrer URL (d.h. die zuvor besuchte Seite), IP-Adresse und der anfragende Provider. Protokolldaten entstehen ohne Zuordnung zu Ihrer Person oder sonstiger Profilerstellung.
      </p>

      <h4>E-Mail Kontakt</h4>
      <p>
        Wenn Sie mit uns in Kontakt treten (z. B. per Kontaktformular oder E-Mail), speichern wir Ihre Angaben. Weitere personenbezogene Daten speichern und nutzen wir nur, wenn Sie dazu einwilligen oder dies ohne besondere Einwilligung gesetzlich zulässig ist.
      </p>

      <h4>Google Analytics</h4>
      <p>
        Wir benutzen Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Benutzung dieser Website durch die Seitenbesucher werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
      </p>
      <p>
        Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google jedoch innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die IP-Anonymisierung ist auf dieser Website aktiv. In unserem Auftrag wird Google diese Informationen benutzen, um die Nutzung der Website durch Sie auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen uns gegenüber zu erbringen.
      </p>
      <p>
        Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können.
      </p>
      <p>
        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
      </p>

      <h4>Rechtsgrundlagen und Speicherdauer</h4>
      <p>
        Rechtsgrundlage der Datenverarbeitung nach den vorstehenden Ziffern ist Art 6 Abs. 1 Buchstabe f DSGVO. Unsere Interessen an der Datenverarbeitung sind insbesondere die Sicherstellung des Betriebes und der Sicherheit der Webseite, die Untersuchung der Art und Weise der Nutzung der Webseite durch Besucher, und die Vereinfachung der Nutzung der Webseite.
        Sofern nicht spezifisch angegeben speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der verfolgten Zwecke notwendig ist.
      </p>
    </div>
    <div class="content-item">
      <h3>3. Ihre Rechte als von der Datenverarbeitung Betroffener</h3>
      <p>
        Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte bezüglich ihrer personenbezogenen Daten. Möchten Sie diese Rechte geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail oder per Post unter eindeutiger Identifizierung Ihrer Person an die in Ziffer 1 genannte Adresse. Nachfolgend finden Sie eine Übersicht über Ihre Rechte.
      </p>

      <h4>Recht auf Bestätigung und Auskunft</h4>
      <p>
        Sie haben jederzeit das Recht, von uns eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene Daten verarbeitet werden. Ist dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche Auskunft über die zu Ihnen gespeicherten personenbezogenen Daten nebst einer Kopie dieser Daten zu erlangen. Es besteht ein Recht auf folgende Informationen:
      </p>
      <ul>
        <li>die Verarbeitungszwecke;</li>
        <li>die Kategorien personenbezogener Daten, die verarbeitet werden;</li>
        <li>die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organsationen;</li>
        <li>falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;</li>
        <li>das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts  gegen diese Verarbeitung;</li>
        <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
        <li>wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;</li>
        <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Absätze 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für Sie.</li>
      </ul>
      <p>
        Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie das Recht, über die geeigneten Garantien gemäß Artikel 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
      </p>

      <h4>Recht auf Berichtigung</h4>
      <p>
        Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.
      </p>

      <h4>Recht auf Löschung („Recht auf Vergessenwerden“)</h4>
      <p>
        Sie haben das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden. Wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
      </p>
      <ul>
        <li>Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</li>
        <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Artikel 6 Absatz 1 DSGVO Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</li>
        <li>Sie legen gemäß Artikel 21 Absatz 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäß Artikel 21 Absatz 2 DSGVO Widerspruch gegen die Verarbeitung ein.</li>
        <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
        <li>Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitglied	sstaaten erforderlich, dem wir unterliegen.</li>
        <li>Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Artikel 8 Absatz 1 DSGVO erhoben.</li>
      </ul>
      <p>
        Haben wir die personenbezogenen Daten öffentlich gemacht und sind wir zu deren Löschung verpflichtet, so treffen wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
      </p>

      <h4>Recht auf Einschränkung der Verarbeitung</h4>
      <p>
        Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
      </p>
      <ul>
        <li>die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,</li>
        <li>die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnten und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;</li>
        <li>wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigten, oder</li>
        <li>Sie Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 DSGVO eingelegt haben, solange noch nicht feststeht, ob die berechtigten Gründe 	unseres Unternehmens gegenüber den Ihren überwiegen.</li>
      </ul>

      <h4>Recht auf Datenübertragbarkeit</h4>
      <p>
        Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern
      </p>
      <ul>
        <li>die Verarbeitung auf einer Einwilligung gemäß Artikel 6 Absatz 1 Buchstabe a DSGVO oder Artikel 9 Absatz 2 Buchstabe a DSGVO oder auf einem Vertrag gemäß Artikel 6 Absatz 1 Buchstabe b DSGVO beruht und</li>
        <li>die Verarbeitung mit Hilfe automatisierter Verfahren erfolgt.</li>
      </ul>
      <p>
        Bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Absatz 1 haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.
      </p>

      <h4>Recht auf Widerspruch</h4>
      <p>
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Wir verarbeiten die personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </p>
      <p>
        Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
      </p>
      <p>
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie betreffende Verarbeitung Sie betreffender personenbezogener Daten, die zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Artikel 89 Absatz 1 DSGVO erfolgt, Widerspruch einzulegen, es sei denn, die Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich.
      </p>

      <h4>Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</h4>
      <p>
        Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
      </p>

      <h4>Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>
      <p>
        Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsortes, ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes, Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist.
      </p>
    </div>
    <div class="content-item">
      <h3>4. Datensicherheit</h3>
      <p>
        Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden Datenschutzge-setze und technischen Möglichkeiten maximal bemüht.
      </p>
      <p>
        Ihre persönlichen Daten werden bei uns verschlüsselt übertragen. Dies gilt für Ihre Bestellungen und auch für das Kundenlogin. Wir nutzen das Codierungssystem SSL (Secure Socket Layer), weisen jedoch darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
      </p>
      <p>
        Zur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmaßnahmen, die wir immer wieder dem Stand der Technik anpassen.
      </p>
      <p>
        Wir gewährleisten außerdem nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können nicht ausgeschlossen werden. Die von uns verwendeten Server werden regelmäßig sorgfältig gesichert.
      </p>
    </div>
    <div class="content-item">
      <h3>5. Weitergabe von Daten an Dritte, keine Datenübertragung ins Nicht-EU-Ausland</h3>
      <p>
        Grundsätzlich verwenden wir Ihre personenbezogenen Daten nur innerhalb unseres Unternehmens. Wenn und soweit wir Dritte im Rahmen der Erfüllung von Verträgen einschalten (etwa Logistik-Dienstleister) erhalten diese personenbezogene Daten nur in dem Umfang, in welchem die Übermittlung für die entsprechende Leistung erforderlich ist.
      </p>
      <p>
        Für den Fall, dass wir bestimmte Teile der Datenverarbeitung auslagern („Auftragsverarbeitung“), verpflichten wir Auftragsverarbeiter vertraglich dazu, personenbezogene Daten nur im Einklang mit den Anforderungen der Datenschutzgesetze zu verwenden und den Schutz der Rechte der betroffenen Person zu gewährleisten.
      </p>
    </div>
  </div>
</section>
