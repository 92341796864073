<section id="ueber">
  <div class="header">
    <div class="content-wrapper">
      <div class="ueber-img">
        <div class="img-wrapper">
          <img src="../../assets/img/amma.jpg" alt="Amma Yeboah">
        </div>
      </div>
      <div class="ueber-content">
        <div class="ueber-intro">
          <h3>{{'WELCOME' | translate}}</h3>
          <h2>{{'ME' | translate}}</h2>
          <p>
            {{'ABOUT_INTRO' | translate}}
          </p>
          <p>
            „ […] I am also hopeful, because I believe deeply in the ability of human beings to remake themselves for the better.”<br>
            <small>- Chimamanda Ngozi Adichie</small>
          </p>
        </div>
        <div class="ueber-summary">
          <h1>{{'ABOUT' | translate}}</h1>

          <div class="summery-item">
            <h3>{{'PERSONAL' | translate}}</h3>
            <ul [innerHTML]="'HOBBY' | translate"></ul>
          </div>

          <h3>{{'PROFESSION' | translate}}</h3>
          <ul [innerHTML]="'PROFESSION_LIST' | translate">
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
