import { Component, Inject, Input, OnInit } from '@angular/core';
import { TitleService } from './_shared/title/title.service';
import { fadeAnimation, timing } from './_shared/animation/fade.animation';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fadeAnimation,
    trigger('fadeFooter', [
      state('idle', style({
        opacity : 1,
      })),
      state('transition', style({
        opacity : 0,
      })),

      transition('idle <=> transition', animate(timing + 'ms ease-in-out'))
    ])
  ]
})
export class AppComponent implements OnInit {

  constructor(
    private titleService: TitleService,
    private pageScrollService: PageScrollService,
    private scrollToService: ScrollToService,
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService

  ) {
    this.translate.use('de');
    console.log(this.translate.currentLang)
  }

  title = 'amma';

  @Input() transition: boolean;

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  onActivate() {

    this.transition = true;

    setTimeout(() => {

        window.scrollTo(0, 0);

        this.transition = false;

      }, timing);
  }

  ngOnInit() {
    this.titleService.boot();
  }
}
