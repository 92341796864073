import {Component, Input, OnInit} from '@angular/core';
import { EasingLogic } from "ngx-page-scroll-core";

@Component({
  selector: 'app-nav-links',
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.scss']
})
export class NavLinksComponent implements OnInit {

  @Input() left: boolean;
  @Input() right: boolean;

  public easeInOutCubic: EasingLogic = (t: number, b: number, c: number, d: number): number => {
    t /= d/2;
    if (t < 1) return c/2*t*t*t + b;
    t -= 2;
    return c/2*(t*t*t + 2) + b;
  }

  public easeInOutExpo: EasingLogic = (t: number, b: number, c: number, d: number): number => {
    t /= d/2;
    if (t < 1) return c/2 * Math.pow( 2, 10 * (t - 1) ) + b;
    t--;
    return c/2 * ( -Math.pow( 2, -10 * t) + 2 ) + b;
  };

  constructor() { }

  ngOnInit(): void {
  }

}
