<nav>
  <div class="lang-switch">
    <div *ngIf="getLang()==='de'" class="lang-icon" (click)="onLanguageChange('en')">
      <img src="../../assets/img/svg/gb.svg" alt="Englische Flagge">
    </div>
    <div *ngIf="getLang()==='en'" class="lang-icon" (click)="onLanguageChange('de')">
      <img src="../../assets/img/svg/de.svg" alt="German flag">
    </div>
  </div>
  <div class="content-wrapper">
    <div class="nav-flex">
      <div class="nav-flex-item navigation">
        <ul>
          <app-nav-links left="true"></app-nav-links>
        </ul>
      </div>

      <div class="nav-flex-item">
        <a pageScroll [pageScrollOffset]="150" [pageScrollSpeed]="750" [pageScrollEasing]="easeInOutCubic" routerLink="/" href="#outlet-host"><img class="logo" src="../../assets/img/svg/logo.svg" alt="Amma Yeboah – Logo"></a>
      </div>

      <div class="nav-flex-item navigation">
        <ul>
          <app-nav-links right="true"></app-nav-links>
        </ul>
      </div>
    </div>
  </div>

  <div class="mobile-menu" (window:resize)="closeNav()" (window:scroll)="closeNav()" [ngClass]="status ? 'opened' : ''">
    <div class="burger" (click)="toggleNav()"></div>
    <div (click)="closeNav()">
      <ul>
        <app-nav-links left="true" right="true"></app-nav-links>
      </ul>
      <div class="white-overlay"></div>
    </div>
  </div>
</nav>
