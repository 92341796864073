import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {StartComponent} from './start/start.component';
import {ImpressumComponent} from './impressum/impressum.component';
import {DatenschutzComponent} from './datenschutz/datenschutz.component';


const routes: Routes = [
  {path: '', component: StartComponent, data: {title: ''}},
  {path: 'impressum', component: ImpressumComponent, data: {title: 'Impressum –'}},
  {path: 'datenschutz', component: DatenschutzComponent, data: {title: ' Datenschutz –'}},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
