// fade.animation.ts

import {trigger, animate, style, group, query, transition} from '@angular/animations';
import {Input} from "@angular/core";

export const timing = 300;

export const fadeAnimation =

  trigger('fadeAnimation', [
    transition('* <=> *', [
      query(':enter, :leave', style({ opacity: 1 })),
      group([
        query(':enter', [
          style({ opacity:0 }),
          animate(timing + 'ms ease-in-out', style({opacity:0 })),
          animate(timing + 'ms ease-in-out', style({opacity:1 }))

        ]),
        query(':leave', [
          style({ opacity:1 }),
          animate(timing + 'ms ease-in-out', style({opacity:0 }))
        ]),
      ])
    ]),
  ]);
