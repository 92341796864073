<section id="kompetenzen">
  <h1>{{'COMPETENCIES'|translate}}</h1>
  <div class="content-wrapper">
    <app-kompetenz-tabs>
      <app-tab tabTitle="Supervision">
        <app-accordion>
          <app-panel [title]="'SINGLE_SUPERVISION' | translate">
            <ul [innerHTML]="'SINGLE_SUPERVISION_LIST'| translate">
            </ul>
          </app-panel>
          <app-panel [title]="'CASE_SUPERVISION'| translate">
            <ul [innerHTML]="'CASE_SUPERVISION_LIST'|translate">
            </ul>
          </app-panel>
          <app-panel [title]="'TEAM_SUPERVISION'| translate">
            <ul [innerHTML]="'TEAM_SUPERVISION_LIST'| translate">
            </ul>
          </app-panel>
          <app-panel [title]="'GROUP_SUPERVISION'| translate">
            <ul [innerHTML]="'GROUP_SUPERVISION_LIST'|translate">
            </ul>
          </app-panel>
          <app-panel [title]="'ORGANIZATIONAL'| translate">
            <ul [innerHTML]="'ORGANIZATIONAL_LIST'|translate">
            </ul>
          </app-panel>
        </app-accordion>
      </app-tab>
      <app-tab tabTitle="Education">
        <p>
          „Education as a practice of freedom […] as human beings striving not just for knowledge in books, but knowledge about how to live in the world.”<br>
          <small>- bell hooks</small>
        </p>
        <app-accordion>
          <app-panel [title]="'EDUCATION_WORK'| translate">
            <ul [innerHTML]="'EDUCATION_WORK_LIST'|translate">
            </ul>
          </app-panel>
          <app-panel [title]="'EDUCATION_TOPIC'| translate">
            <ul [innerHTML]="'EDUCATION_TOPIC_LIST'|translate">
            </ul>
          </app-panel>
        </app-accordion>
      </app-tab>
      <app-tab tabTitle="Coaching">
        <ul [innerHTML]="'COACHING_LIST'|translate">
        </ul>
      </app-tab>
    </app-kompetenz-tabs>
  </div>
</section>
