import { Component, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { PanelComponent } from './panel/panel.component';

import { trigger, state, style, transition, animate} from '@angular/animations';

@Component({
  selector: 'app-accordion',
  template: `
    <ng-content></ng-content>
`,
  styleUrls: ['./accordion.component.scss'],
  animations: [
    trigger('open', [
      state('opened', style({ height : '0px'})),
      state('closed', style({ height : '*'})),

      transition('closed <=> opened', animate('200ms ease-in-out'))
    ])
  ]
})
export class AccordionComponent  implements AfterContentInit {
  @ContentChildren(PanelComponent)
  groups: QueryList<PanelComponent>;

  /**
   * Invoked when all children (groups) are ready
   */
  ngAfterContentInit() {
    // console.log (this.groups);
    // Set active to first element
    this.groups.toArray()[0].opened = true;
    // Loop through all Groups
    this.groups.toArray().forEach((t) => {
      // when title bar is clicked
      // (toggle is an @output event of Group)
      t.toggle.subscribe(() => {
        // Open the group
        this.toggleGroup(t);
      });
      /*t.toggle.subscribe((group) => {
        // Open the group
        this.openGroup(group);
      });*/
    });
  }

  toggleGroup(panel: PanelComponent) {

    if (panel.opened){
      panel.opened = false;
    }
    else {
      // close other groups
      this.groups.toArray().forEach((t) => t.opened = false);
      // open current group
      panel.opened = true;
    }
  }
}
