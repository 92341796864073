import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { NavComponent } from './nav/nav.component';
import { StartComponent } from './start/start.component';
import { NavLinksComponent } from './nav/nav-links/nav-links.component';
import { TitleService } from './_shared/title/title.service';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { UeberComponent } from './ueber/ueber.component';

import { KompetenzenComponent } from './kompetenzen/kompetenzen.component';
import { TabsComponent } from './kompetenzen/tabs/tabs.component';
import { TabComponent } from './kompetenzen/tabs/tab.component';

import { AccordionComponent } from './_shared/interaction/accordion/accordion.component';
import { PanelComponent } from './_shared/interaction/accordion/panel/panel.component';
import { HeaderComponent } from './_shared/layout/header/header.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DatenschutzComponent,
    ImpressumComponent,
    NavComponent,
    StartComponent,
    NavLinksComponent,
    FooterComponent,
    UeberComponent,
    KompetenzenComponent,
    TabsComponent,
    TabComponent,
    AccordionComponent,
    PanelComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ScrollToModule.forRoot(),
    NgxPageScrollModule,
    NgxPageScrollCoreModule.forRoot({duration: 1150, scrollOffset: 150})
  ],
  providers: [TitleService],
  bootstrap: [AppComponent]
})
export class AppModule { }
