import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('headerTitle') title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
