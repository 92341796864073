<app-nav></app-nav>

<div id="outlet-host" class="outlet-host" [@fadeAnimation]="getRouterOutletState(o)">
  <router-outlet #o="outlet" (activate)="onActivate()"></router-outlet>
</div>

<!--<div class="outlet-host">
  <router-outlet></router-outlet>
</div>-->

<app-footer style="display: block" [@fadeFooter]="transition ? 'transition' : 'idle'"></app-footer>


