import {Component, Inject, OnInit} from '@angular/core';
import {PageScrollService} from "ngx-page-scroll-core";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  constructor(private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit(): void {
  }

}
