<app-header headerTitle="Impressum"></app-header>

<section class="impressum padding">
  <div class="content-wrapper">
    <div class="content-item">
      <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
      <p>
        Dr. med. Amma Yeboah <br />
        Supervisorin, DGSv <br>
        Coach, DGSv <br>
      </p>
      <img class="dgsv-logo" src="assets/img/DGSv_Logo_Mitglieder_CMYK_black.jpg" alt="DGSv Logo">
      <p>
        Theodor-Heuss-Ring 23 <br />
        50668 Köln <br />
      </p>
    </div>
    <div class="content-item">
      <h3>Kontakt</h3>
      <p>
        Telefon: +49 (0) 176 66868117<br />
        E-Mail: mail@amma-yeboah.de
      </p>
    </div>
  </div>
</section>
