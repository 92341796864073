import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['../accordion.component.scss'],
  animations: [
    trigger('collapse',[
      state('collapsed', style({
        height : '0px',
      })),
      state('closed', style({
        height : '*',
      })),

      transition('closed <=> collapsed', animate('300ms ease-in-out'))
    ])
  ]
})
export class PanelComponent {

  isCollapsed: boolean;

  @Input() opened = false;
  @Input() title: string;
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();
}
