import { Component, OnInit} from '@angular/core';
import { EasingLogic } from "ngx-page-scroll-core";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(
    private translate: TranslateService
  ) {
  }

  status = false;
  toggleNav(){
    this.status = !this.status;
  }

  closeNav(){
    if (this.status) {
      this.status = false;
    }
  }

  getLang() {
    return this.translate.currentLang;
  }

  onLanguageChange(lang: string) {
    this.translate.use(lang)
  }

  public easeInOutCubic: EasingLogic = (t: number, b: number, c: number, d: number): number => {
    t /= d/2;
    if (t < 1) return c/2*t*t*t + b;
    t -= 2;
    return c/2*(t*t*t + 2) + b;
  }

  ngOnInit(): void {
  }

}
