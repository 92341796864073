<footer>
  <div class="content-wrapper">
    <div>
      <ul>
        <app-nav-links left="true" right="true"></app-nav-links>
      </ul>
      <div class="footer-contact">
        <p>
          Dr. med. Amma Yeboah<br>
          Theodor-Heuss-Ring 23<br>
          50668 Köln
        </p>
        <p>
          {{'PHONE' | translate}}: +49 (0) 176 66868117<br>
          {{'MAIL' | translate}}: mail@amma-yeboah.de
        </p>
      </div>
      <p>
        © 2020 Amma Yeboah. {{'RIGHTS_CLAIM' | translate}}
      </p>
    </div>
    <div class="brand-grey">
      <img src="../../assets/img/svg/brand-footer.svg" alt="Logo Brand – Amma Yeboah">
    </div>
  </div>
</footer>
